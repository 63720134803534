.q-toolbar {
  min-height: 64px;
  transition: background-color 250ms;
  will-change: background-color;
}
.q-input {
  max-width: 768px;
}
.menuIcon {
  position: absolute;
  z-index: 10;
  background-color: #fff;
  border-radius: 100%;
  font-size: 0.25em;
  line-height: 0.25em;
  padding: 4px;
  bottom: -2px;
  right: -2px;
}
.branding-logo {
  background: #fff;
  border-radius: 50%;
  padding: 2px;
  margin-top: 5px;
  margin-bottom: -5px;
}
/*# sourceMappingURL=src/layouts/basic/heart/appbar.css.map */